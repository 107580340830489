// progressStore.js
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('progress', {
  state: () => ({
    activeRequests: 0,
  }),
  getters: {
    isLoading: (state) => {
      return state.activeRequests > 0
    },
  },
  actions: {
    startLoading() {
      this.activeRequests++
    },
    finishLoading() {
      if (this.activeRequests > 0) {
        this.activeRequests--
      }
    },
  },
})
