import {
  CategoryWithBudgets,
  NewCategory,
  UpdateCategory,
} from '@/types/Category'
import { defineStore } from 'pinia'
import { useLoadingStore } from './loading'
import {
  extractYearAndMonth,
  formatCurrency,
  getCurrentYearAndMonth,
} from '@/utils'
import { useTransactionsStore } from './transaction'
import { SelectedCategory } from '@/types/BalanceTransaction'

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    categories: [] as CategoryWithBudgets[], // Initialize with an empty array
  }),
  getters: {
    getCategoryById: (state) => (categoryId: string) => {
      return state.categories.find(
        (category) => category.category.categoryId === categoryId,
      )
    },
    checkIfMonthlyBudgetExists: (state) => (month: string) => {
      return state.categories.some((category) => {
        return (
          category.budgets.hasOwnProperty(month) &&
          category.budgets[month] !== null
        )
      })
    },
    getIncomeCategories: (state) => {
      return state.categories.filter((category) => category.category.isIncome)
    },
    getSortedCategories: (state) => {
      return state.categories.sort((a, b) => {
        if (a.category.isIncome) {
          return -1
        } else if (b.category.isIncome) {
          return 1
        } else {
          return 0
        }
      })
    },
    getRemainingBudget: (state) => {
      // Get the total income
      const totalIncome = state.categories
        .filter((category) => category.category.isIncome)
        .reduce(
          (total, category) =>
            total +
            (parseFloat(category.budgets[getCurrentYearAndMonth()].budgeted) ??
              0),
          0,
        )

      // TODO: Replace with getter
      const totalBudgeted = state.categories
        .filter((category) => !category.category.isIncome)
        .reduce(
          (total, category) =>
            total +
            (parseFloat(category.budgets[getCurrentYearAndMonth()].budgeted) ??
              0),
          0,
        )

      // Subtract the total budgeted amount from the total income
      return totalIncome - totalBudgeted
    },
    getSpentAmountForCategory:
      (state) => (category: string, yearMonth: string) => {
        const transactionsStore = useTransactionsStore()
        const categoryWithBudgets = state.categories.find(
          (c) => c.category.categoryId === category,
        )
        if (categoryWithBudgets) {
          const budget = categoryWithBudgets.budgets[yearMonth]
          if (budget) {
            return Object.keys(budget.transactions).reduce(
              (total, transactionId) => {
                let transaction =
                  transactionsStore.getTransactionById(transactionId)
                if (!transaction) {
                  return total
                }
                return (
                  total +
                  (categoryWithBudgets.category.isIncome
                    ? -budget.transactions[transactionId].amount
                    : budget.transactions[transactionId].amount)
                )
              },
              0, // Ensure the initial value is a number
            )
          }
        }
        return 0
      },
    getTotalBudgeted: (state) => {
      return state.categories
        .filter((category) => !category.category.isIncome)
        .reduce(
          (total, category) =>
            total +
            (parseFloat(category.budgets[getCurrentYearAndMonth()].budgeted) ??
              0),
          0,
        )
    },
    getTotalIncome: (state) => {
      return state.categories
        .filter((category) => category.category.isIncome)
        .reduce(
          (total, category) =>
            total +
            (parseFloat(category.budgets[getCurrentYearAndMonth()].budgeted) ??
              0),
          0,
        )
    },
  },
  actions: {
    async fetchCategories() {
      const loadingStore = useLoadingStore()
      try {
        loadingStore.startLoading()
        const response = await this.axios.get('/categories')
        this.categories = response.data.categories
        loadingStore.finishLoading()
      } catch (error) {
        console.error(error)
      }
    },

    async createCategory(newCategory: NewCategory) {
      try {
        const response = await this.axios.post('/categories', newCategory)
        // this.categories.push(response.data)
      } catch (error) {
        console.error(error)
      }
    },

    async createNewMonthlyBudgetForUser() {
      try {
        const response = await this.axios.post(
          '/categories/createNewMonthlyBudgetForUser',
        )
        this.categories = response.data
      } catch (error) {
        console.error(error)
      }
    },
    async updateCategory(updatedCategory: UpdateCategory) {
      try {
        const response = await this.axios.post(
          `/categories/${updatedCategory.categoryId}`,
          updatedCategory,
        )
        console.log(response.data)
        this.fetchCategories()
      } catch (error) {
        console.error(error)
      }
    },
    async deactivateCategory(categoryId: string) {
      try {
        const response = await this.axios.delete(`/categories/${categoryId}`)
        console.log(response.data)
        this.fetchCategories()

        const transactionsStore = useTransactionsStore()
        const yearAndMonth = getCurrentYearAndMonth()
        Object.values(transactionsStore.transactions[yearAndMonth]).forEach(
          (transaction) => {
            if (
              transaction.balanceCategories?.some(
                (category: SelectedCategory) =>
                  category.categoryId == categoryId,
              )
            ) {
              transactionsStore.removeCategoriesForTransaction(
                transaction.transaction_id,
              )
            }
          },
        )
      } catch (error) {
        console.error(error)
      }
    },
    async removeTransactionFromCategory(
      categoryId: string,
      transactionId: string,
      yearAndMonth: string,
    ) {
      const category = this.getCategoryById(categoryId)
      if (category) {
        if (category.budgets.hasOwnProperty(yearAndMonth)) {
          const budget = category.budgets[yearAndMonth]
          if (budget.transactions.hasOwnProperty(transactionId)) {
            delete budget.transactions[transactionId]
          }
        }
      }
    },

    async addTransactionToCategory(
      categoryId: string,
      transactionId: string,
      yearAndMonth: string,
      amount: number,
    ) {
      const category = this.getCategoryById(categoryId)
      if (category) {
        let budget = category.budgets[yearAndMonth]
        budget.transactions[transactionId] = { amount }
      }
    },
    async initializeCategories() {
      if (this.categories.length === 0) {
        await this.fetchCategories()
      }
      return this.categories
    },
  },
})
